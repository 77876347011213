import {useTranslation} from 'next-i18next'
import AppTypographyLink from 'src/components/elements/typography/AppTypographyLink'
import AppTypography from 'src/components/elements/typography/AppTypography'
import {useCurrentLocale} from 'src/hooks/locale'
import {LOCALE_EN} from 'src/constants/locale'
import {dayjs} from 'src/utils/date'
import AppIcon from 'src/components/elements/icons/AppIcon'
import {Fragment} from 'react'

const rightLinks = [
  {
    label: 'terms_and_conditions',
    path: '/conditions-d-utilisation',
    path_en: '/terms-of-use',
  },
  {
    label: 'about_us',
    path: '/aproposdenous',
    path_en: '/about-us',
  },
  /* {
    label: 'careers',
    path: '/carrieres',
    path_en: '/career',
  }, */
  {
    label: 'Blog',
    path: '/blog',
    path_en: '/blog',
  },
  {
    label: 'help',
    path: 'https://help.monsieurchalets.com',
    path_en: 'https://help.monsieurchalets.com',
  },
  {
    label: 'cottages_for_sale',
    path: '/chalets-a-vendre',
    path_en: '/chalets-for-sale',
  },
  {
    label: 'owner_section',
    path: '/formulaires/section-proprietaire',
    path_en: '/formulaires/section-proprietaire',
  },
  {
    label: 'contact_us',
    path: '/contacteznous',
    path_en: '/contact-us',
  },
]
const currentYear = dayjs().format('YYYY')

export default function SecondaryFooter() {
  const {t} = useTranslation('footer')
  const currentLocale = useCurrentLocale()

  return (
    <Fragment>
      <div className="flex flex-col xl:flex-row justify-between items-start xl:items-center mb-4 gap-2">
        <div className="flex flex-col xl:flex-row justify-start items-start gap-2">
          <div>
            <AppTypography className="text-white opacity-70" variant="body">
              © 2015 - {currentYear}
              &nbsp;|&nbsp;MonsieurChalets.com.
              <span className="hidden md:inline-block">&nbsp;|&nbsp;</span>
              <br className="inline-block md:hidden" />
              <span className="inline-block mt-3 md:mt-0">
                {t('all_rights_reserved')}
              </span>
            </AppTypography>
            <div className="flex flex-row justify-start items-center gap-2 mt-3 md:mt-0.5">
              <AppIcon
                name="fleur-de-lys"
                className="w-4 h-4 min-w-4 block opacity-70"
              />
              <AppTypography className="text-white opacity-70" variant="body">
                {t('quebec_company')}
              </AppTypography>
            </div>
          </div>
          {/*  <div className={clsx(classes.links, classes.linksLessGap)}>
            {links.map(({path, label}, index) => (
              <Fragment key={index}>
                <AppTypographyLink
                  key={index}
                  href={path}
                  className={classes.link}
                  color="textPrimary"
                  variant="body2"
                >
                  {t(label)}
                </AppTypographyLink>
                {!isSmallSize && links.length - 1 !== index && <> | </>}
              </Fragment>
            ))}
          </div> */}
        </div>

        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 md:gap-6 mb-16 lg:mb-4 xl:mb-0 mt-6 md:mt-0">
          {rightLinks.map(({path, path_en, label}, index) => (
            <AppTypographyLink
              key={index}
              href={currentLocale === LOCALE_EN ? path_en : path}
              className="text-white opacity-70"
            >
              {t(label, {ns: 'common'})}
            </AppTypographyLink>
          ))}
        </div>
      </div>
    </Fragment>
  )
}
